<template>
  <div class="product" v-if="product.name">
    <a class="product-link" v-if="!product.isStaticProduct">
      <div class="product-content">
        <div class="product-content-top">
          <div class="brand-image">
            <img :src="getProductImage" :alt="product.name" />
          </div>
          <div class="product-content-props">
            <VueHeadline
              class="product-content-headline"
              level="2"
              weightLevel="3"
              color="grey-40"
              >{{ product.name }}</VueHeadline
            >
            <div style="display: flex; align-items: center; margin-bottom: 15px">
              <div style="font-weight: 400; font-size: 14px; color: #79838e">
                <div style="display: flex; align-items: center; margin-bottom: 20px">
                  <img src="~@/assets/icons/arrows-vertical.svg" />
                  <span style="color: black; margin-left: 15px">{{ getUnitOfLength }}</span>
                </div>
                <div style="display: flex; align-items: center; margin-bottom: 20px">
                  <img src="~@/assets/icons/envelope.svg" />
                  <span style="color: black; margin-left: 15px">{{ getPackage }}</span>
                </div>
                <div style="display: flex; align-items: center">
                  <img src="~@/assets/icons/radius.svg" />
                  <span style="color: black; margin-left: 15px">{{ getRadius }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="product-content-bottom">
          <div class="border-seperate"></div>
          <div
            style="margin-top:30px; display: flex; align-items: center; justify-content: center;
"
          >
            <img
              style="width: 310px"
              :style="setProductFilterDimension"
              :src="getBrandFiltreImage"
              alt="brand"
            />
            <img
              style="margin-left:10px; margin-right: 10px;"
              src="~@/assets/icons/right-direction-arrow.svg"
            />
            <div style="text-align:center; font-weight: 400; font-size: 12px; color: #79838e">
              <span style="color: black;">{{ getFilterType }}</span>
            </div>
          </div>
          <div>
            <div style="display: flex; margin: 18px 0; justify-content: center">
              <div
                style="
                    margin-right: 20px;
                    font-weight: 400;
                    font-size: 12px;
                    color: #79838e;
                    display: flex;
                    align-items: center;
                  "
              >
                <img
                  style="margin-right: 5px"
                  src="~@/assets/icons/karbonmonoksit.svg"
                  alt="zifir"
                />
                Karbonmonoksit:
                <span style="color: black; margin-left: 5px"
                  >{{ productProps['KARBONMONOKSIT'] }} mg</span
                >
              </div>
              <div
                style="
                    margin-right: 20px;
                    font-weight: 400;
                    font-size: 12px;
                    color: #79838e;
                    display: flex;
                    align-items: center;
                  "
              >
                <img style="margin-right: 5px" src="~@/assets/icons/nikotin.svg" alt="nikotin" />

                Nikotin:
                <span style="color: black; margin-left: 5px">{{ productProps['NIKOTIN'] }} mg</span>
              </div>
              <div
                style="
                    font-weight: 400;
                    font-size: 12px;
                    color: #79838e;
                    display: flex;
                    align-items: center;
                  "
              >
                <img style="margin-right: 5px" src="~@/assets/icons/zifir.svg" alt="zifir" />
                Zifir:
                <span style="color: black; margin-left: 5px">{{ productProps['ZIFIR'] }} mg</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
    <a v-else>
      <img style="width: 100%;" :src="getProductImage" :alt="product.name" />
    </a>
  </div>
</template>
<script>
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';

export default {
  name: 'BrandProductDetail',
  components: {
    VueHeadline,
  },
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      productProps: {},
    };
  },
  created() {
    if (!this.product.props) return;
    this.productProps = Object.assign({}, ...this.product.props.map(k => ({ [k.Key]: k.Value })));
  },
  computed: {
    getFilterType() {
      return this.productProps['FILTRE OZELLIK'];
    },
    getUnitOfLength() {
      return this.productProps['UZUNLUK'];
    },
    getPackage() {
      return this.productProps['PAKET TIPI'];
    },
    getRadius() {
      return this.productProps['CAP'];
    },
    getProductImage() {
      return this.product.images;
    },
    getBrandFiltreImage() {
      return this.product.filterImageUrl;
    },
  },
  methods: {
    setProductFilterDimension() {
      switch (this.productProps['CAP']) {
        case 'Standart Çap':
          return { height: '35px' };
        case 'İnce Çap':
          return { height: '20px' };
        case 'Ekstra İnce Çap':
          return { height: '25px' };
        default:
          break;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.product {
  margin-bottom: palette-space-level('10');
  width: 100%;

  &-link {
    display: block;
    padding: 20px 15px;
    border: 1px solid palette-color-level('grey', '20');
    border-radius: palette-radius-level('2');
  }
  &-content {
    &-headline {
      font-size: 20px;
      margin-top: palette-space-level('10');
      margin-bottom: palette-space-level('25');
    }
    &-top {
      display: flex;
      justify-content: center;
      .brand-image {
        width: 110px;
        height: 195px;

        img {
          width: 100%;
          max-height: 100%;
          word-break: break-all;
        }
      }
    }
    &-props {
      margin-left: 30px;
      &-item {
        display: flex;
        width: 100%;
        align-items: center;
        padding: palette-space-level('3') 0;
        margin-right: palette-space-level('20');

        p:first-child {
          margin-right: palette-space-level('5');
        }
      }
    }
  }
}
.border-seperate {
  height: 1px;
  width: calc(100% + 30px);
  margin: palette-space-level('8') -15px;
  background-color: palette-color-level('grey', '15');
}
</style>
