<template id="divBrandDetailWrapper">
  <div v-if="brandData.brand" class="brand-detail">
    <div class="content-row">
      <div class="content-layout-fixer">
        <img v-if="getBrandData.detailImg" :src="getBrandData.detailImg" :alt="getBrandData.name" />
      </div>
    </div>
    <div v-if="getBrandData.name != 'Marlboro'" class="content-row">
      <div class="content-layout-fixer">
        <div class="landing">
          <div
            class="logo"
            :style="{
              backgroundPosition: 'center',
              backgroundSize: '80%',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${getBrandData.img}`,
            }"
          ></div>
          <div class="right-area">
            <VueHeadline level="3" weightLevel="6" color="grey-40" class="headline-text">{{
              getBrandData.name
            }}</VueHeadline>
            <VueText
              sizeLevel="3"
              color="grey-30"
              class="text"
              :class="{ 'text-toggled': isDescToggled }"
              v-html="getBrandData.desc"
            >
            </VueText>
            <div class="link-holder" v-if="getBrandData.desc">
              <VueButton ghost @click="toggleDesc">
                <VueText sizeLevel="3" weightLevel="3" class="left-link"
                  >Daha {{ isDescToggled ? 'Az' : 'Fazla' }} Göster</VueText
                ></VueButton
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex;flex-flow: column;align-items: center;justify-items: center;" v-else>
      <div class="swiper-container text-center">
        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in brandHistory"
            :key="index"
            class="swiper-slide"
            @click="onBrandHistory(item)"
          >
            <div class="timestamp" />
            <div :class="[currentBrandDetail.id == item.id ? 'status-after' : 'status']">
              <div
                style="margin-top: 25px; display:flex; flex-direction: column; align-items: center; cursor: pointer"
              >
                <img
                  style="margin-bottom: 20px; width: 60px; height: 90px"
                  :src="item.imageUrl ? item.imageUrl : ''"
                  alt="brand"
                />
                <span
                  :id="`brand-date + ${index}`"
                  style="font-size: 20px"
                  :class="[currentBrandDetail.id == item.id ? 'selected-date' : '']"
                  >{{ item.date }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; margin: 5% 5% 10% 15%">
        <img
          style="width: 200px; height: 300px"
          :src="currentBrandDetail.imageUrl ? currentBrandDetail.imageUrl : ''"
          alt="brand detail"
        />
        <div style="margin-left: 90px">
          <div style="font-size: 24px">{{ currentBrandDetail.date }}</div>
          <ul v-for="(bullet, i) in getBullets" :key="i">
            <li style="margin: 25px 50px 0 0; font-weight: 400; font-size: 14px">{{ bullet }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content-row">
      <div class="content-layout-fixer">
        <VueHeadline class="products-headline" level="1" weightLevel="3">Ürünler</VueHeadline>
        <div class="products-wrapper">
          <BrandProductDetail
            v-for="product in brandData.products"
            :key="product.productId"
            :product="product"
          ></BrandProductDetail>
        </div>
      </div>
    </div>
    <div class="content-row grey-bg">
      <div class="content-layout-fixer">
        <VueHeadline class="products-headline" level="1" weightLevel="3"
          >Marka Haberleri</VueHeadline
        >
        <BrandContentList :contents="news" @showMoreClicked="showMore" :showButton="getMoreNews">
        </BrandContentList>
      </div>
    </div>
  </div>
</template>
<script>
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import BrandContentList from '@/components/brand/BrandContentList/BrandContentList.vue';
import BrandProductDetail from '@/components/brand/BrandProduct/BrandProductDetail.vue';
import ContentApi from '@/services/Api/content';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import StorageProps from '@/mixins/storageProps.js';
import { Product, Content } from '@/services/Api/index';
import { maxBy } from '@/mixins/arrayUtils.js';

export default {
  name: 'PMIBrandDetail',
  components: {
    VueButton,
    VueText,
    VueHeadline,
    BrandProductDetail,
    BrandContentList,
  },
  mixins: [StorageProps],
  data() {
    return {
      news: [],
      getMoreNews: true,
      brandData: {},
      isDescToggled: false,
      lastIndex: 0,
      lastId: 0,
      pageSize: 3,
      brandHistory: [],
      currentBrandDetail: null,
      otherIds: [],
    };
  },
  created() {
    this.getContents();
  },
  mounted() {
    ContentApi.getBrandHistory({ BrandName: 'Marlboro' }).then(res => {
      if (res?.data) {
        this.brandHistory = res.data.Data.map((element, index) => {
          return { ...element, id: index };
        });

        this.currentBrandDetail = this.brandHistory[0];
      }
    });
    Product.getBrandById(this.$route.params.id).then(res => {
      const {
        Data: { brandDetail },
      } = res.data;
      this.brandData = brandDetail;
      this.$route.meta.breadcrumb[2].name = brandDetail.brand.name;
    });
  },
  computed: {
    constants() {
      return {
        icons: COMPONENT_CONSTANTS.ICON_VARIABLES,
      };
    },
    getBrandData() {
      let name,
        desc,
        img = '',
        detailImg = '';
      name = this.brandData.brand.name;
      desc = this.brandData.content;
      img = this.brandData.brand.image;
      detailImg = this.brandData.backgroundImage;

      return { name, desc, img, detailImg };
    },
    getBullets() {
      return this.currentBrandDetail?.bullets ?? [];
    },
  },
  methods: {
    onBrandHistory(brand) {
      this.currentBrandDetail = brand;
    },
    goBack() {
      this.$router.go(-1);
    },
    toggleDesc() {
      this.isDescToggled = !this.isDescToggled;
    },
    showMore() {
      this.lastIndex = this.news.length;
      this.lastId = maxBy(this.news, 'feedId');
      this.pageSize = 9;
      this.getContents(true);
    },
    getContents(isNextPage) {
      const brandId = this.$route.params.id;
      Content.getBrandNews(this.lastId, this.lastIndex, this.pageSize, brandId).then(res => {
        const {
          Data: { contents, isFeedContinue },
        } = res.data;
        this.getMoreNews = isFeedContinue;
        if (isNextPage) {
          this.news.push(...contents);
        } else {
          this.news = contents;
        }
        if (this.news.length) {
          this.lastIndex = this.news.length;
          this.lastId = this.news[this.lastIndex - 1].feedId;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.brand-detail {
  padding-bottom: palette-space-level('40');
  .back-button {
    margin: palette-space-level(30) 0;
    padding-left: palette-space-level(20);
    svg {
      cursor: pointer;
    }
  }
  .content-row {
    height: unset;
  }
  .white-bg {
    background: palette-color-level('white', '100');
    margin-bottom: palette-space-level('60');
  }
  .grey-bg {
    background: palette-color-level('grey', 90);
    margin-bottom: palette-space-level('60');
    padding-top: palette-space-level(60);
    padding-bottom: palette-space-level(30);
  }
  .landing {
    display: flex;
    padding: palette-space-level(40) 0 palette-space-level(40) palette-space-level(20);
    .logo {
      background-color: palette-color-level('white', '100');
      min-width: 170px;
      height: 170px;
      border: 1px solid palette-color-level('grey', '20');
      border-radius: palette-radius-level('1');
    }

    .right-area {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-left: palette-space-level(18);

      .headline-text {
        margin-bottom: palette-space-level(10);
      }
      .text {
        position: relative;
        line-height: 1.27;
        padding-right: palette-space-level(10);
        max-height: palette-space-level(30);
        overflow: hidden;
        max-width: 100%;

        &:after {
          content: '...';
          position: absolute;
          background: palette-color-level('white', '100');
          right: 11px;
          bottom: 0;
        }
        &-toggled {
          max-height: unset;

          &:after {
            display: none;
          }
        }
      }

      .link-holder {
        display: flex;
        flex-direction: row;
        align-items: space-between;

        .left-link {
          padding-right: palette-space-level(30);
        }
        .left-link,
        .right-link {
          padding-top: palette-space-level(10);
          line-height: 1.27;
          text-decoration: underline;
        }
      }
    }
  }
  .content-wrapper {
    padding: palette-space-level('20');

    &:last-child {
      border-top: 1px solid palette-color-level('grey', '15');
      border-bottom: 1px solid palette-color-level('grey', '15');
    }
  }
  .products-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: palette-space-level(20);
    grid-row-gap: palette-space-level(40);
    padding-left: palette-space-level(20);
  }
  .products-headline {
    padding: 0 0 palette-space-level(30) palette-space-level(20);
  }
}

.timestamp {
  margin: auto;
  margin-bottom: 5px;
  padding: 0px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.status {
  padding: 10px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #b4c2d3;
  position: relative;
  transition: all 200ms ease-in;
}
.status span {
  padding-top: 8px;
}
.status span:before {
  content: '';
  width: 12px;
  height: 12px;
  background-color: #b4c2d3;
  border-radius: 12px;
  border: 2px solid #b4c2d3;
  position: absolute;
  left: 50%;
  top: 0%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 200ms ease-in;
}

.status-after {
  padding: 10px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #b4c2d3;
  position: relative;
  transition: all 200ms ease-in;
}
.status-after span {
  padding-top: 8px;
}
.status-after span:before {
  content: '';
  width: 12px;
  height: 12px;
  background-color: #cc1c4e;
  border-radius: 12px;
  border: 2px solid #cc1c4e;
  position: absolute;
  left: 50%;
  top: 0%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 200ms ease-in;
}
.swiper-container {
  width: 95%;
  margin: auto;
  padding: 50px 50px 0px 50px;
  overflow-y: auto;
}
.swiper-wrapper {
  display: inline-flex;
  flex-direction: row;
  overflow-y: auto;
  justify-content: center;
}
.swiper-container::-webkit-scrollbar-track {
  background: #a8a8a8b6;
}
.swiper-container::-webkit-scrollbar {
  height: 2px;
}
.swiper-container::-webkit-scrollbar-thumb {
  background: #4f4f4f !important;
}
.swiper-slide {
  text-align: center;
  font-size: 12px;
  width: 200px;
  height: 100%;
  position: relative;
}
.selected-date {
  color: #cc1c4e;
}

.selected-time {
  padding: 10px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #b4c2d3;
  position: relative;
  transition: all 200ms ease-in;
}
.selected-time span {
  padding-top: 8px;
}
</style>
